/*
 * @Author: vince
 * @Date: 2021-02-09 10:30:35
 * @LastEditors: vince 273187189@qq.com
 * @LastEditTime: 2023-06-26 15:17:46
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'babel-polyfill';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/assets/css/main.css'
import MetaInfo from 'vue-meta-info'
import { VPopover } from 'v-tooltip'

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper.css';

Vue.use(MetaInfo)
Vue.component('v-popover', VPopover)
Vue.config.productionTip = false
// let head = document.getElementsByTagName('head');        // 创建head标签
// let meta = document.createElement('meta');           // 创建meta标签
// meta.name = "keywords";

//跳转路由时始终在页面最顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  // window.pageYOffset = 0
  if (to.name == 'Main' || to.name == 'subjectResources' || to.name == 'pcPlatform' || to.name == 'newDynamic' || to.name == 'we' || to.name == 'reportDetail') {
    store.dispatch("setDetail", to.name)
  } else {
    store.dispatch("setDetail", 'zhinengyingyong')
  }
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  // meta.content = to.meta.keywords
  // head[0].appendChild(meta)
  next()


})


new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // document.dispatchEvent(new Event('render-event'))
    document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount('#app')
