import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//解决跳转相同地址报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import(/* webpackChunkName: "about" */ '../views/main.vue'),
    meta: {
      title: '学多多官网',
      keywords: '学多多教育科技；上海学多多教育科技有限公司；学多多；绘本森林；慧测评；慧研修；绘教乐学；绘教慧学；小学生综合素质评价；智慧教育；教育信息化；'
    }
  },
  {
    path: '/hykt',
    name: 'Xdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/xdd.vue'),
    meta: {
      title: '学多多官网—学多多慧云课堂',
      keywords: '国学经典；小学教育；K12；移动课堂；课本同步微课；中英文有声绘本故事；课本点读；古诗词学习,牛津阅读树,剑桥少儿英语,朗文；奥数；语文,数学,翻转课堂；教学评价,互动；学习平台；音乐；编程,学霸笔记,口语评测,语文听写作业,口算速算,在线作业；'
    }
  },
  {
    path: '/hbsl',
    name: 'HuiBen',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/huiben.vue'),
    meta: {
      title: '学多多官网—绘本森林',
      keywords: '绘本森林;绘本森林APP；森林绘本;上海学多多教育科技有限公司出品;学多多教育;儿童读物;中英绘本；儿童故事；幼儿阅读；图画书；有声读物；科普绘本；音乐绘本；声音动听；绘本阅读；亲子阅读；育儿；绘本故事；经典绘本故事；'
    }
  },
  {
    path: '/szjz',
    name: 'Szjz',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/szjz.vue'),
    meta: {
      title: '学多多官网—多多数字基座',
      keywords: '学生成长手册；知行校园；家长学校；慧测评；慧活动；新生书院；'
    }
  },
  {
    path: '/ddpjs',
    name: 'Ddpjs',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/ddpjs.vue'),
    meta: {
      title: '学多多官网—多多评价树',
      keywords: '课堂评价；行为规范；班级巡检；教师巡课；报告'
    }
  },
  {
    path: '/xlwy',
    name: 'Xlwy',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/xlwy.vue'),
    meta: {
      title: '学多多官网—心理无忧',
      keywords: '量表测量；脑电波测量；心理机器人'
    }
  },
  {
    path: '/zbt',
    name: 'Zbt',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/zbt.vue'),
    meta: {
      title: '学多多官网—支部通',
      keywords: '入党流程；党建工作；党日活动'
    }
  },
  {
    path: '/hjlx',
    name: 'HuiJiao',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/huijiao.vue'),
    meta: {
      title: '学多多官网—绘教慧学',
      keywords: '绘教慧学；绘教乐学；绘本教学；绘本教学材料包；绘本阅读；绘本课程；幼儿园五大领域；学科融合；'
    }
  },
  {
    path: '/hyx',
    name: 'Jyx',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/jiayanxiu.vue'),
    meta: {
      title: '学多多官网—慧研修',
      keywords: '教师研修；教师培训；学科教研；教师专业发展；教师成长；'
    }
  },
  {
    path: '/zhszpj',
    name: 'Zp',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/zp.vue'),
    meta: {
      title: '学多多官网—综合素质评价',
      keywords: '小学生综合素质评价；学业素养；品行素养；身心健康；艺术素养；学生成长档案；学生全面发展；核心素养；评价指标；学习兴趣；学习习惯；学业成果；'
    }
  },
  {
    path: '/hcp',
    name: 'Hcp',
    component: () => import(/* webpackChunkName: "about" */ '../views/application/hcp.vue'),
    meta: {
      title: '学多多官网—慧测评',
      keywords: '快乐闯关；智慧测评；游园闯关；期末测评；基于课标测评；游戏化测评；期中测评；等第制评价；情景式测评；'
    }
  },
  {
    path: '/course',
    name: 'subjectResources',
    component: () => import(/* webpackChunkName: "about" */ '../views/subjectResources.vue'),
    meta: {
      title: '学多多官网—课程资源',
      keywords: '精品微课；专题课程；富媒体课程；道德法制；传统文化；科普百科；生命健康；职业规划；安全教育；综合实践；学科学习；阅读素养；研学游学；STEM及创客；环保课程；'
    }
  },
  {
    path: '/xxhpt',
    name: 'pcPlatform',
    component: () => import(/* webpackChunkName: "about" */ '../views/pcPlatform.vue'),
    meta: {
      title: '学多多官网—信息化平台',
      keywords: '校本课程定制；区本课程定制；校级平台定制；区级平台定制；信息化调研；学前资源库；心理测评；走班选课；教师研修；招生考试；'
    }
  },
  {
    path: '/wisdomClass',
    name: 'wisdomClass',
    component: () => import(/* webpackChunkName: "about" */ '../views/wisdomClass.vue'),
    meta: {
      title: '学多多官网—慧园课堂',
      keywords: '校本课程定制；区本课程定制；校级平台定制；区级平台定制；信息化调研；学前资源库；心理测评；走班选课；教师研修；招生考试；'
    }
  },
  {
    path: '/news',
    name: 'newDynamic',
    component: () => import(/* webpackChunkName: "about" */ '../views/newDynamic.vue'),
    meta: {
      title: '学多多官网—最新动态',
      keywords: '学多多企业动态；学多多最新发展；'
    }
  },
  {
    path: '/aboutus',
    name: 'we',
    component: () => import(/* webpackChunkName: "about" */ '../views/we.vue'),
    meta: {
      title: '学多多官网—关于我们',
      keywords: '学多多公司简介；学多多公司荣誉；学多多合作伙伴；学多多企业文化；'
    }
  },
  {
    path: '/reportDetail',
    name: 'reportDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/reportDetail.vue'),
    meta: {
      title: '学多多官网—媒体报道',
      keywords: ''
    }
  },

]

const router = new VueRouter({
  routes,
  // mode: 'hash',
  mode: 'history',
  // base: '/website/',
  base: '/'
})
/*根据服务器目录添加base，如果是根目录则为/*/
export default router
