import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu: null,
    detail: 'Main'
  },
  getters: {
    getMenu(state) {
      return state.menu;
    },
    getDetail(state) {
      return state.detail;
    },
  },
  mutations: {
    setMenu:(state, value) => {
      state.menu = value
    },
    setDetail:(state, value) => {
      state.detail = value
    },
  },
  actions: {
    setMenu: ({commit}, info) => {
      commit('setMenu', info);
    },
    setDetail: ({commit}, info) => {
      commit('setDetail', info);
    },
  },
  modules: {
  }
})
